/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { produce } from 'immer';
import merge from 'lodash/merge';
import type { SessionData } from './types';
import { StateStorage } from 'zustand/middleware';
import { getSessionStoreData, updateServerSession } from './session-utils';
import { createAppStore } from '@marriott/mi-store-utils';
import { getEnvProp } from '../helpers';
import { logger } from '../logger';
import { getMvpOffersData } from '../mvpOffers';

export type UserSessionActions = {
  updateSession: (data: SessionData) => void;
  setSyncedWithServer: (synced: boolean) => void;
};

export type UserSessionState = {
  // stored user session attributes
  session: SessionData;
  syncedWithServer: boolean;
};

export type SessionStoreStr = {
  state: UserSessionState;
  version: number;
};

let serverData: SessionData | null = null;
// fallback when server LastUpdated is not available
const largestTimeStamp = 999999999999999;
const initalState: UserSessionState = {
  session: {},
  syncedWithServer: false,
};

export const serverSyncLocalStorage: StateStorage = {
  setItem(name, value) {
    const valueObj = JSON.parse(value) as SessionStoreStr;
    const lastUpdatedNumber = Number(serverData?.data?.lastUpdated);
    // if server data timestamp is behind the session store timestamp, push to server
    if (
      lastUpdatedNumber < Number(valueObj?.state?.session?.data?.lastUpdated) &&
      lastUpdatedNumber !== largestTimeStamp &&
      valueObj?.state?.syncedWithServer === false
    ) {
      // push to server
      updateServerSession({
        createOrUpdate: {
          ...valueObj.state.session.data,
        },
        name: 'SyncServerPush',
      });
    }

    window.localStorage.setItem(name, value);
  },
  getItem(name) {
    const sessionStr: SessionStoreStr = JSON.parse(
      window.localStorage.getItem(name) ||
        JSON.stringify({
          version: 0,
          state: {
            ...initalState,
          },
        })
    ) as SessionStoreStr;

    return JSON.stringify({
      ...sessionStr,
      state: {
        ...sessionStr.state,
        syncedWithServer: false, // set to false on initial load
      },
    });
  },
  removeItem(name) {
    window.localStorage.removeItem(name);
  },
};

// @ts-ignore - error TS2347: Untyped function calls may not accept type arguments.
export const useUserSessionStore = createAppStore<UserSessionState & UserSessionActions>(
  // @ts-ignore - error TS7006: Parameter 'set' implicitly has an 'any' type.
  set => ({
    setSyncedWithServer: (synced: boolean) => {
      set(
        (state: UserSessionState) =>
          produce(state, (draft: UserSessionState) => {
            draft.syncedWithServer = synced;
          }),
        false,
        // @ts-ignore - provide a action name for devtools
        'SessionServerSync'
      );
    },
    updateSession: (data: SessionData) => {
      set(
        (state: UserSessionState) =>
          produce(state, (draft: UserSessionState) => {
            merge(draft, {
              session: {
                data: {
                  ...data.data,
                  lastUpdated:
                    data.data?.lastUpdated && data.data?.lastUpdated !== String(largestTimeStamp)
                      ? data.data?.lastUpdated
                      : String(Date.now()),
                },
              },
            });
          }),
        false,
        // @ts-ignore - provide a action name for devtools
        'UpdateSession'
      );
    },
  }),
  {
    usePersistentStore: true,
    persistentStoreName: 'mi-session-store',
    devToolsName: 'mi-session-store',
    customStorage: serverSyncLocalStorage,
    onRehydrateStorage: async (state: UserSessionState & UserSessionActions) => {
      const { log } = logger({ requestID: '', sessionID: state?.session?.data?.sessionToken })('UserSessionStore');
      if (
        typeof window === 'undefined' ||
        getEnvProp('NEXT_PUBLIC_ENABLE_WEBSDK') !== 'true' ||
        String(window.__NEXT_DATA__?.props?.['pageProps']?.model?.enableWebSDK) !== 'true'
      ) {
        return;
      }
      const browserDataStr = window.localStorage.getItem('mi-session-store');
      let browserData: SessionStoreStr = {} as SessionStoreStr;
      try {
        browserData = JSON.parse(browserDataStr || '{}') as SessionStoreStr;
      } catch (e) {
        log.warn('Unable to parse session store data', e);
      }
      try {
        const promises = [];

        if (getEnvProp('NEXT_PUBLIC_ENABLE_PREQUAL') === 'true') {
          promises.push(getMvpOffersData(window.__NEXT_DATA__?.props?.['pageProps']));
        }
        if (!serverData) {
          promises.push(getSessionStoreData());
        }
        const result = await Promise.allSettled(promises);
        if (!result?.length) {
          log.error('Session store promise results are empty');
          return;
        }
        const [mvpOffersData, sessionDataResult] = promises.length > 1 ? result : [null, result[0]];
        if (mvpOffersData && mvpOffersData.status === 'fulfilled') {
          window.mvpOffers = mvpOffersData.value;
          log.info('MVP Offers updated');
        } else {
          log.warn('MVP Offers failed');
        }

        if (sessionDataResult && sessionDataResult.status === 'fulfilled') {
          serverData = sessionDataResult.value as SessionData;
          if (serverData.data) {
            serverData.data.lastUpdated = (Number(serverData.data?.lastUpdated) || largestTimeStamp).toString();
          }
          // serverData = (await getSessionStoreData()) as SessionData;
          const browserTimeStamp = Number(browserData.state?.session?.data?.lastUpdated) || 0;
          const serverTimeStamp = Number(serverData.data?.lastUpdated);
          log.info('Checking timestamps for session update', browserTimeStamp, serverTimeStamp);
          state.setSyncedWithServer(true);
          if (browserTimeStamp < serverTimeStamp) {
            state.updateSession({
              ...browserData?.state?.session,
              ...serverData,
              data: {
                ...serverData.data,
                lastUpdated: serverTimeStamp !== largestTimeStamp ? String(serverTimeStamp) : String(Date.now()),
              },
            });
            log.info('Updating session state with server data');
            state.setSyncedWithServer(true);
          } else {
            log.info('Client has latest session data, skipping update');
            state.setSyncedWithServer(true);
          }
        } else {
          log.error('Get ssession failed');
        }
      } catch (error) {
        log.error('Error fetching session data', error);
      }
    },
  }
);
